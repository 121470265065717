.ripple-effect {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple-animation 0.6s linear;
  background-color: rgba(255, 255, 255, 0.4);
}

.button {
  position: relative;
  overflow: hidden;
  background-color: var(--primary-color);
  color: #fff; 
  border: none;
  border-radius: 5px;
  
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  font-size: 1rem; 
  padding: 0.75em 1.5em;
}

.button .label {
  font-size: inherit;
  font-weight: bold;
}

.button i {
  font-size: 1.25rem; 
}

.button-xs {
  padding: 0.5em 1em;
  font-size: 0.875rem; 
}

.button-md {
  padding: 0.75em 1.5em;
  font-size: 1rem;
}

.button-lg {
  padding: 1em 2em;
  font-size: 1.125rem; 
}

@keyframes ripple-animation {
  to {
    transform: scale(4);
    opacity: 0;
  }
}

/* Media Queries */
@media (max-width: 768px) {
  .button {
    font-size: 0.875rem; 
    padding: 0.75em 1.25em; 
  }

  .button-xs {
    padding: 0.5em 0.75em;
    font-size: 0.75rem;
  }

  .button-md {
    padding: 0.75em 1.25em;
    font-size: 0.875rem; 
  }

  .button-lg {
    padding: 0.875em 1.75em;
    font-size: 1rem; 
    width: 110px;
  }
}

@media (max-width: 480px) {
  .button {
    font-size: 0.75rem; 
    padding: 0.5em 1em; 
  }

  .button-xs {
    padding: 0.25em 0.5em;
    font-size: 0.75rem; 
  }

  .button-md {
    padding: 0.5em 1em;
    font-size: 0.75rem; 
  }

  .button-lg {
    padding: 0.75em 1.5em;
    font-size: 0.875rem; 
  }
}
