
:root {
  --primary-color: #3f88f8;
  --secondary-color: #2ecc71;
  --background-color: #282c34;
  --background-panel: #00000042;
  --text-color: #ffffff;
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

a{
  text-decoration: none;
}
body{
  background-color: #282c34;
  margin-top: 10px;
  animation: fadeIn 1s ease-in-out; 
  overflow: hidden;
}

p, span, h1, h2, h3, h4, h5{
  color: white;
}

.text-primary{
  color: var(--text-color);
}

.text-white{
  color: var(--text-color);
}

.boder-right{
    border-right: 1px solid black;
}

.text-center{
  text-align: center;
}

.text-small{
  font-size: 12px;
}


.text-left{
  text-align: left;
}
.App {
  text-align: center;
  max-width: 900px;
  margin: 0 auto;
  animation: fadeIn 1s ease-in-out; 
  height: 100vh;
  overflow: scroll;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


::-webkit-scrollbar {
  width: 12px; 
  height: 12px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  opacity: 0;
  transition: opacity 0.3s;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.7);
}

* {
  scrollbar-width: thin; 
  scrollbar-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.1); 
}


*::-webkit-scrollbar-thumb,
*::-webkit-scrollbar-thumb:hover {
  opacity: 0; 
}

*:hover::-webkit-scrollbar-thumb,
*:active::-webkit-scrollbar-thumb,
*:focus::-webkit-scrollbar-thumb {
  opacity: 1;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
