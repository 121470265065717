.contentHeader{
    width: 80%;
    background-color: var(--background-panel);
    margin: 0 auto;
    padding: 20px;
    border-radius: 24px;
    height: 100px;
    position: relative;
}

.logo{
    width: 117px;
    position: absolute;
    top: 23px;
    left: 18px;
}

.avatarImage{
    width: 90px;
    height: 90px;
    border-radius: 100%;
    object-fit: cover;
}



@media (max-width: 600px) {
  .logo{
    display: none;
  }
}
