/* src/components/Divider.css */

.divider {
  display: flex;
  align-items: center;
  text-align: center;
  width: 80%;
  margin: 20px auto;
  border-top: 2px solid #eeeeee2b;
  position: relative;
}
