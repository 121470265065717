
.arrow {
  background: none;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  top: 55px;
  z-index: 999;
}

.arrow i {
  color: var(--primary-color);
}

.left-arrow {
  position: absolute;
  left: 28px;
    
}

.right-arrow {
  position: absolute;
  right: 28px;
}