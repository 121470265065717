#frens{
  padding-bottom: 200px;
  text-align: left;
}

#frens button{
  font-size: 13px;
}

.wallet-transfer {
  background-color: transparent; 
  color: #ffffff;          
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  text-align: center;
  border: 1px solid #eeeeee2b;
  margin: 20px auto;
  font-family: Arial, sans-serif;
  text-align: left;
}

.wallet-balance {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.usdt-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.balance-amount {
  font-size: 1.5em;
}

.transfer-text {
  margin: 10px 0;
}

.transfer-input {
  background-color: #333;
  color: #aaa;
  border: none;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 10px;
  text-align: center;
}

.transfer-button {
  background-color: #00a86b;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.transfer-button:hover {
  background-color: #007f50;
}

.transfer-icon {
  font-size: 1.2em;
}



.user-list-container {
  color: white;
  padding: 10px;
  width: 80%;
  max-height: 150px;
  margin: 0 auto;
  overflow: scroll;
  overflow-x: hidden;
  
}


.user-list-container::-webkit-scrollbar {
  width: 10px;
}

.user-list-container::-webkit-scrollbar-track {
  background: #333333;
}

.user-list-container::-webkit-scrollbar-thumb {
  background-color: #fff; 
  border-radius: 10px;
  border: 2px solid #2a2a2a; 
}


.user-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
}

.user-name, .user-balance {
  font-size: 14px;
}

.claim-button {
  background-color: #00a8a8;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 12px;
}

.claim-button:hover {
  background-color: #008a8a;
}

.content-icon-copy{
  position: absolute;
  right: 20px;
  bottom: 16px;
  color: #fee;
  font-size: 20px;
  cursor: pointer;
}