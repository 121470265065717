input{
  background-color: #827f7f17;
  color: #e68686;
  border: none;
  border-radius: 5px;
  padding-left: 20px;
  width: 100%;
  font-size: 14px;
  
}


input.input-xs{
  height: 35px;
}

input.input-md{
  /*padding: 20px 0px; */
  height: 50px;
}

.label-input{
  font-size: 20px;
  display: block;
  margin-bottom: 10px;
  color: #ccc;
}

.content-input{
  width: 100%;
  position: relative;
  display: flex
}
.span-action{
  position: absolute;
  right: 20px;
  top: 34%;
}


@media (max-width: 600px) {
  input{
    font-size: 12px;
  }
}
