#layout-title img{
     width: 30px;
    height: 30px;
    margin-right: 5px;
    margin-top: -5px;
  }

#layout-title{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}
