.performance-summary {
    align-items: center;
    color: #fff;
    width: 85%;
    border-radius: 10px;
    margin: 50px auto 0px;
}


.content-performance-title{
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    background-color: var(--background-panel);
    border-radius: 5px;
    padding-block: 8px;
    
}


.content-performance-value{
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    border-radius: 5px;
    padding-block: 8px;
}

.performance-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-block: 30px;
    
    width: 33%;
}



.performance-title {
    font-size: 18px;
    margin: 0;
    width: 33%;
}

.performance-value {
    font-size: 24px;
    margin: 5px 0 0 0;
    width: 33%;
}

.task {
    text-align: center;
}

.task-title {
    font-size: 20px;
    color: #00ff00;
    margin: 0;
}

.task-description {
    font-size: 16px;
    margin: 10px 0 0 0;
}



@media (max-width: 600px) {
  .performance-title{
    font-size: 13px;
  }
}
