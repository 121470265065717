#wallet{
  padding-bottom: 200px;
}

.balance-container {
  color: white;
  padding: 10px;
  border-bottom: 1px solid #eeeeee2e;
  text-align: left;
  padding-inline: 20px;
}

.balance-section {
  margin-bottom: 20px;
}

.balance-label {
  font-size: 20px;
  color: #ffffff8f;
  
}

.info-icon {
  margin-left: 10px;
  font-size: 12px;
  cursor: pointer;
  color: #ffffff8f;
}

.balance-amount {
  margin-top: 5px;
  font-size: 16px;
}

.transactions{
  text-align: left;
  padding-inline: 20px;
}

.listItem{
  display: flex;
  flex-direction: row;
  background-color: var(--background-panel);
  padding: 20px;
  justify-content: space-between;
  margin-bottom: 15px;
  color: #fff;
}

.content-buttons-wallet{
  display: flex;
  justify-content: space-between;
  padding-inline: 30px;
}

.deposit-only{
  font-size: 15px;
}



.transaction-details {
  background-color: var(--background-panel);
  color: #ffffff;         
  padding: 10px;
  border-radius: 5px;
  font-family: Arial, sans-serif;
  min-height: 266px;
  margin: 0 auto;
  width: 95%;
}

.transaction-amount {
  font-weight: bold;
}

.transaction-address {
  
  word-break: break-all; 
}

.transaction-dates {
  font-size: 0.9em;
  opacity: 0.8;
}

.text-support{
  font-size: 12px;
  display: none;
}
.text-support i{
  color: #fff;
}

@media (max-width: 600px) {
  .listItem{
    font-size: 14px;
  }

  .transaction-details{
    font-size: 14px;
  }

  .text-support{
    display: block;
  }

}

