.bottom-navigation-content {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.bottom-navigation{
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #1d2127;
  padding: 10px 0;
  border-top: 2px solid #333; 
  border-radius: 20px;
  max-width: 900px;
  margin: 0 auto;
}

.nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  cursor: pointer;
}

.nav-item span {
  margin-top: 4px;
  font-size: 12px;
}


.activaitemnav, .activaitemnav span{
  color: var(--primary-color);
}